import React, { Component } from 'react';

class History extends Component {
  render() {

    return (
        <section id="history">
          <div className="row historytitle">
            <h1>History</h1>
            <hr></hr>
          </div>

        <div className="row historydiv">
           <div className="three columns header-col">
              <h1><span>Origins</span></h1>
              <div className="historyimg">
                <img src={process.env.PUBLIC_URL + "images/dellasantacasa.jpg"} alt="Basilica della Santa Casa" />
                <p>Basilica della Santa Casa</p>
              </div>
           </div>

           <div className="nine columns main-col">
              <div className="row item">
                 <div className="twelve columns">
                  <div key="origins">
                    <p className="info">The "Temple Expiatori de la Sagrada Família," shortened to "La Sagrada Família," was originally the idea of Josep María Bocabella. Its full name, translated to English, means "Expiatory Temple of the Holy Family." Bocabella traveled to Rome in 1872 and on his way back, he stopped in Loreto, Italy, and saw the Basilica della Santa Casa. Upon seeing the alluring church, Bocabella wanted to replicate it in Barcelona, dedicated to the Holy Family.</p>
                  </div>
                 </div>
              </div>
           </div>
        </div>

        <div className="row historydiv">
           <div className="three columns header-col">
              <h1><span>The Architect</span></h1>
              <div className="historyimg">
                <img src={process.env.PUBLIC_URL + "images/794px-Antoni_Gaudi_1878.jpg"} alt="Antoni Gaudí" />
                <p>Antoni Gaudí</p>
              </div>
           </div>

           <div className="nine columns main-col">
              <div className="row item">
                 <div className="twelve columns">
                  <div key="thearchitect">
                    <p className="info">After Bocabella obtained the land needed for the temple, in 1877 the architect Francisco de Paula del Villar offered to design the Basilica for free. Due to disagreements based around material costs, Francisco de Paula del Villar resigned from the project in 1883 and Antoni Gaudí received control over the project, while still working on other buildings. When Gaudí took over, he lead the project in a different direction, transforming it into "the church of the future." Years later, in 1914, Gaudí dedicated himself entirely to the Sagrada Família, working solely on it until his death in 1926.</p>
                  </div>
                 </div>
              </div>
           </div>
        </div>

        <div className="row historydiv">
           <div className="three columns header-col">
              <h1><span>Design</span></h1>
              <div className="historyimg">
                <img src={process.env.PUBLIC_URL + "images/auriane-clement-zTfC-Pos3_U-unsplash.jpg"} alt="Interior of the Sagrada Família" />
                <p>Interior of the Sagrada Família</p>
              </div>
           </div>

           <div className="nine columns main-col">
              <div className="row item">
                 <div className="twelve columns">
                  <div key="design">
                    <p className="info">Francisco de Paula del Villar's original design featured neo-Gothic elements, commonplace at the time. Gaudí maintained many aspects of his plan, but moved away from the Gothic style in some ways. The Sagrada Família's design contains many complex designs and three-dimensional figures.</p>
                  </div>
                 </div>
              </div>
           </div>
        </div>

        <div className="row historydiv">
           <div className="three columns header-col">
              <h1><span>Construction</span></h1>
              <div className="historyimg">
                <img src={process.env.PUBLIC_URL + "images/lsf_original_design.jpg"} alt="Francisco de Paula del Villar's Original Design" />
                <p>Francisco de Paula del Villar's Original Design</p>
              </div>
           </div>

           <div className="nine columns main-col">
              <div className="row item">
                 <div className="twelve columns">
                  <div key="construction">
                    <p className="info">Still unfinished, construction of the Sagrada Família began on March 19th, 1882, nearly 140 years ago. The construction has been interrupted and halted many times over the years, for various reasons such as issues with funding, the Spanish Civil War, and the COVID-19 pandemic. Difficulties in understanding some of Gaudí's complex architecture have also lengthened the Sagrada Família's construction process. Currently, there is no scheduled completion date.</p>
                  </div>
                 </div>
              </div>
           </div>
        </div>

        <div className="row historydiv">
           <div className="three columns header-col">
              <h1><span>Contrasting Views</span></h1>
              <div className="historyimg">
                <img src={process.env.PUBLIC_URL + "images/serena-naclerio-7wB28uc_xdI-unsplash.jpg"} alt="The Sagrada Família Under Construction" />
                <p>The Sagrada Família Under Construction</p>
              </div>
           </div>

           <div className="nine columns main-col">
              <div className="row item">
                 <div className="twelve columns">
                  <div key="contrastingviews">
                    <p className="info">The project has had a long history of contrasting opinions in Barcelona, spawning debates throughout the years. Some have praised the work, while others are opposed to completing the construction or even to the project as a whole. Barcelona's former chief planner, Oriol Bohigas, has said the Sagrada Família is "a barbarity" and that "everything they are doing is horrible."</p>
                  </div>
                 </div>
              </div>
           </div>
        </div>

        <div className="row historydiv">
           <div className="three columns header-col">
              <h1><span>Modern Technologies</span></h1>
              <div className="historyimg">
                <img src={process.env.PUBLIC_URL + "images/pelayo-arbues-M_3PbDgAGc8-unsplash.jpg"} alt="3D Printed Prototypes" />
                <p>3D Printed Prototypes in the Sagrada Família Workshop</p>
              </div>
           </div>

           <div className="nine columns main-col">
              <div className="row item">
                 <div className="twelve columns">
                  <div key="moderntechnologies">
                    <p className="info">As the project entered the 21st-century, the Sagrada Família has started to incorporate modern technologies into the construction process. For 20 years, 3D printing and computer-aided design (CAD) has assisted the project. The technology has accelerated the construction, immensely speeding up prototyping of the temple's many complex features.</p>
                  </div>
                 </div>
              </div>
           </div>
        </div>

        <div className="row historydiv">
           <div className="three columns header-col">
              <h1><span>The Future</span></h1>
              <div className="historyimg">
                <img src={process.env.PUBLIC_URL + "images/lsf_virgin_mary_star.jpg"} alt="The Star of the Virgin Mary" />
                <p>The Star of the Virgin Mary, placed Nov. 29th, 2021</p>
              </div>
           </div>

           <div className="nine columns main-col">
              <div className="row item">
                 <div className="twelve columns">
                  <div key="thefuture">
                    <p className="info">With many milestones taking place in recent years, the Sagrada Família is nearing its completion— after a century and a half of work. Previously, architectural work on the building was scheduled to be completed in 2026, finally making the Sagrada Família a reality and forever changing Barcelona's skyline. Due to the COVID-19 pandemic, that date has been pushed back, with no current completion date.</p>
                  </div>
                 </div>
              </div>
           </div>
        </div>

      </section>
    );
  }
}

export default History;
