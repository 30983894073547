import React, { Component } from 'react';

class Print extends Component {
  render() {

    return (
      <section id="theprint">
      <div className="row">
         <div className="columns main-col">
            <h2>The Print</h2>

            <p>As 3D printing plays an important role in the construction of the Sagrada Família, I 3D printed a 1:1000 scale model of the completed building. Below is a timelapse video of the entire 114-hour long print. </p>
         </div>
      </div>
      <video controls>
        <source src={process.env.PUBLIC_URL + "video/LSF2.mp4"} type="video/mp4" />
      </video>

   </section>
    );
  }
}

export default Print;
