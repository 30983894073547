import React, { Component } from 'react';

class Quiz extends Component {
  render() {

    return (
      <section id="quiz">
      <div className="row">
         <div className="nine columns main-col">
            <h2>Take the Quiz</h2>

            <p>Want to test your knowledge of the Sagrada Família? Take this quiz to see what you have learned!</p>
         </div>
      </div>
      <div className="iframeWrapper">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScmuptlT2U1lwzAapiw4ZZ9akwsz2DiLRE8x0oWhic2e9f5bQ/viewform?embedded=true">Loading…</iframe>
      </div>

   </section>
    );
  }
}

export default Quiz;
