import React, { Component } from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Print from './Components/Print';
import Quiz from './Components/Quiz';
import History from './Components/History';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      foo: 'bar'
    };

    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);

  }

  render() {
    return (
      <div className="App">
        <Header/>
        <History/>
        <Print/>
        <Quiz/>
        <Footer/>
      </div>
    );
  }
}

export default App;
