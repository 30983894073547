import React, { Component } from 'react';

class Header extends Component {
  render() {

    return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
	         <li><a className="smoothscroll" href="#history">History</a></li>
            <li><a className="smoothscroll" href="#theprint">The Print</a></li>
            <li><a className="smoothscroll" href="#quiz">Take the Quiz</a></li>
         </ul>

      </nav>

      <div className="row banner">
         <div className="banner-text">
            <h1 className="responsive-headline">La Sagrada Família</h1>
            <h3>Discover the fascinating history behind the Sagrada Família</h3>
            <hr />
            <p className="explorebtn">
               <a className="smoothscroll" href="#history"><i className="icon-down-circle"></i></a>
            </p>
         </div>
      </div>

   </header>
    );
  }
}

export default Header;
